import React from 'react'

const ButtonDivider = ({text, link, backgroundColor, color}) => {

    return (
        <a href={link} target='_blank' class='button-divider' style={{backgroundColor: backgroundColor, color: color}}>           
            <p>{text}</p>
        </a>
    )
}

export default ButtonDivider