import React, { useRef, useEffect } from 'react'

import '../styles/globals.scss';

import { gsap } from 'gsap';

import { ScrollTrigger } from "gsap/ScrollTrigger";

import VideoContainer from '../components/video-container';
import TextContainer from '../components/text-container';
import TextDivider from '../components/text-divider';
import ButtonDivider from '../components/button-divider';

//assets
import streamerVideo from '../videos/streamer.mp4';
import waifuVideo from '../videos/waifu.mp4';
import caffVideo from '../videos/caff-crunch.mp4';
import balloonVideo from '../videos/balloon.mp4';
import parasiteVideo from '../videos/parasite.mp4';

import logo from '../images/logo.png'

gsap.registerPlugin(ScrollTrigger);

const Home = () => {

  // const initIntroAnimation = () => {
  //     const tlIntro = gsap.timeline({ onComplete: () => {
  //       initScrollAnimation();         
  //     } });

  //     tlIntro.addLabel('start')
  //    // .to('.main-logo', { duration: 1, scale: 1}, 'start+=0.1')
      
  // };

const initScrollAnimation = () => {

      //gsap definitions
      const tl = gsap.timeline({
          scrollTrigger: {
            trigger: "#section-1",
            start: "top top",
            end: "bottom",              
            pin: true,
            scrub: 0.1
          }
      });

      tl.addLabel('start')
      .to('.main-logo', { scale: 0.1, x: '50%'}, 'start+=0.1')      
      .to('.logo-container', { height: '10vh'}, 'start+=0.1')
      .to('.grid', { y: '0'}, 'start+=0.1')
      .to('.left', { x: '0%'}, 'start+=0.1')
      .to('.right', { x: '0%'}, 'start+=0.1') 
      .set({}, {}, "+=0.1")      
      .addLabel('sequence-end');

  };

  useEffect(() => {
      let ctx = gsap.context(() => {
        initScrollAnimation();
      });

      return () => ctx.revert();
  }, []);      

  return (
    <main className='page-container'>
      <div id='section-1'>

        <div class='logo-container'>
          <img className='main-logo' src={logo} alt="Barcode" />
        </div>

        <div className='grid'>
        
          <div className='project-row left'>
              <VideoContainer source={streamerVideo} />

              <TextDivider
                text={`F in the chat?`}

                color={'#FFF'}
                backgroundColor={'#7000f7'} />          

              <TextContainer
                text={`Streamer Ban Bingo. Get a set of nine random streamers.
                If all nine get banned within a year,
                the first to hit BINGO will win $10,000`}

                color={'#000'}
                backgroundColor={'rgb(211, 211, 211)'} />

              <ButtonDivider
                text={`Get that money`}
                link={'https://streamerbanbingo.com/'}
                color={'#FFF'}
                backgroundColor={'#7000f7'} />                
          </div>

          <div className='project-row right'>

              <TextDivider
                text={`Waifu?`}

                color={'#FFF'}
                backgroundColor={'#FF6EC7'} />          

              <VideoContainer source={waifuVideo} />    

              <TextContainer
                text={`A Waifu for the new age.

                A precision engineered dakimakura to keep you safe in any situation.`}

                color={'#000'}
                backgroundColor={'rgb(211, 211, 211)'} />            

              <ButtonDivider
                text={`UwU`}
                link={'https://laifusavingwaifu.com/'}
                color={'#FFF'}
                backgroundColor={'#FF6EC7'} />         
     
                
          </div>

          <div className='project-row left'>
              <TextDivider
                text={`Is that a sausage?`}

                color={'#FFF'}
                backgroundColor={'#ee5023'} />          

              <TextContainer
                text={`Say good morning to the world's first fry-up flavoured cereal!`}

                color={'#000'}
                backgroundColor={'rgb(211, 211, 211)'} />            

              <ButtonDivider
                text={`Yes Geezer!`}
                link={'https://caffcrunch.com/'}
                color={'#FFF'}
                backgroundColor={'#ee5023'} />    

              <VideoContainer source={caffVideo} />                               
          </div>   


          <div className='project-row right'>
              <TextDivider
                text={`More sausages?!`}

                color={'#FFF'}
                backgroundColor={'#012169'} />          

              <TextContainer
                text={`Let's celebrate our new king with a royal sausage salute`}

                color={'#000'}
                backgroundColor={'rgb(211, 211, 211)'} />            

              <TextContainer
                text={`God save our gammon king`}
                
                color={'#FFF'}
                backgroundColor={'#012169'} />    

              <VideoContainer source={balloonVideo} />                               
          </div>    

          <div className='project-row left'>
              <TextDivider
                text={`Get paid to talk trash`}

                color={'#FFF'}
                backgroundColor={'#25e126'} />      

              <VideoContainer source={parasiteVideo} />      

              <TextContainer
                text={`What's better than hearing angry teenagers on your game? ADS! ADS! ADVERTISEMENTS!`}

                color={'#000'}
                backgroundColor={'rgb(211, 211, 211)'} />            

              <TextContainer
                text={`Invading your ears soon`}
                
                color={'#FFF'}
                backgroundColor={'#25e126'} />    

                                           
          </div>               

        </div>
        
      </div>

      <footer>
        <p>PBPMLL0012 Limited. Company Number: 15063327</p>
      </footer>      
    </main>
  )
}

export default Home