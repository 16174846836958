import React from 'react'

const TextDivider = ({text, backgroundColor, color}) => {

    return (
        <div class='text-divider-container' style={{backgroundColor: backgroundColor, color: color}}>           
            <p>{text}</p>
        </div>
    )
}

export default TextDivider