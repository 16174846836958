import React from 'react'

const VideoContainer = ({source}) => {

    return (
        <div class='video-container'>           
            <video src={source} playsInline autoPlay muted loop />
        </div>
    )
}

export default VideoContainer