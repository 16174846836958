import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './pages/Home';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          {/* <Route path='/' exact component={<Home />} /> */}
          <Route path='/caff-crunch' element={<Home />} />
          <Route path='/streamer-ban-bingo' element={<Home />} />
          <Route path='/cctv' element={<Home />} />
        </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

